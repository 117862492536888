div#nav_wrapper {
  position: absolute;
}
body.blur #wrapper {
  -webkit-filter: blur(0px);
  filter: blur(0px);
}
.top_bar.hasbg {
  background: rgba(0, 0, 0, 0.3);
  height: 5rem;
}

.top_contact_info > div.company_address {
  width: 70%;
}
.single_car_booking_wrapper, .single_car_share_wrapper{
  border: 1px solid #dce0e0;
  margin-bottom: 10%;
}
.single_car_booking_wrapper.book_instantly input[type=submit] {
  color: #f7f6f6;
  background: #1d1b1b;
  border-color: black;
}
#page_content_wrapper img, .page_content_wrapper img {
  max-width: 100%;
  height: 225px;
}
.logo_wrapper img {
  max-width: 202px;
  height: 58px;
  margin-top: 0px;
  display: inline-block;
  width: 70%;
}
div#page_content_wrapper.booking-wrapper-form {
  width: 50%;
}
.page_content_wrapper img {
  max-width: 100%;
  height: 46px;
  margin-top: 5px;
  margin-bottom: -11px;
}
#menu_wrapper div .nav 
  {
    list-style: none;
    display: inline-flex;
  }
.header-covid-img{
  margin:-13px 2px;
}
@media(max-width:992px){
  .logo_wrapper img {
    max-width: 202px;
    height: 58px;
    margin-top: 0px;
    display: inline-block;
    width: 56%;
}
div#page_content_wrapper.booking-wrapper-form{
  width: 100%;
}
  .top_bar.hasbg {
    background: rgba(0, 0, 0, 0.3);
    height: 4rem;
  }
  #logo_transparent.logo_container {
    left: 0;
    top: 15px;
    margin-top: -7%;
  }

}